var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",attrs:{"variant":"white","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" Detalles cliente ")])]),_c('b-card',[_c('validation-observer',{ref:"editClient"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre',"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre'},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Alias',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Alias',"label-for":"client-alias"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Alias'},model:{value:(_vm.client.alias),callback:function ($$v) {_vm.$set(_vm.client, "alias", $$v)},expression:"client.alias"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'CIF',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'CIF',"label-for":"client-cif"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'CIF'},model:{value:(_vm.client.cif),callback:function ($$v) {_vm.$set(_vm.client, "cif", $$v)},expression:"client.cif"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Dirección',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Dirección',"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Dirección'},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Código postal',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Código postal',"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Código postal'},model:{value:(_vm.client.postalCode),callback:function ($$v) {_vm.$set(_vm.client, "postalCode", $$v)},expression:"client.postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Pais',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Pais',"label-for":"client-country"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Pais'},model:{value:(_vm.client.country),callback:function ($$v) {_vm.$set(_vm.client, "country", $$v)},expression:"client.country"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Ciudad',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Ciudad',"label-for":"client-city"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Ciudad'},model:{value:(_vm.client.city),callback:function ($$v) {_vm.$set(_vm.client, "city", $$v)},expression:"client.city"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Telefono',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Telefono',"label-for":"client-phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Telefono'},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Email',"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email',"label-for":"client-email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Email'},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Web',"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Web'},model:{value:(_vm.client.web),callback:function ($$v) {_vm.$set(_vm.client, "web", $$v)},expression:"client.web"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":'Imagen',"label-for":"client-image"}},[(_vm.clientPrepare)?_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.files,"maxFiles":"1"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Comentarios',"label-for":"client-comments"}},[_c('quill-editor',{model:{value:(_vm.client.comments),callback:function ($$v) {_vm.$set(_vm.client, "comments", $$v)},expression:"client.comments"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Datos de contacto")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{attrs:{"name":'Persona de contacto',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Persona de contacto',"label-for":"client-contact_person"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Persona de contacto'},model:{value:(_vm.client.contact_person),callback:function ($$v) {_vm.$set(_vm.client, "contact_person", $$v)},expression:"client.contact_person"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{attrs:{"name":'Email de contacto',"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email de contacto',"label-for":"client-contact_person_email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Email de contacto'},model:{value:(_vm.client.contact_person_email),callback:function ($$v) {_vm.$set(_vm.client, "contact_person_email", $$v)},expression:"client.contact_person_email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{attrs:{"name":'Teléfono de contacto',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Teléfono de contacto',"label-for":"client-contact_person_phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Teléfono de contacto'},model:{value:(_vm.client.contact_person_phone),callback:function ($$v) {_vm.$set(_vm.client, "contact_person_phone", $$v)},expression:"client.contact_person_phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s('Enviar')+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }